import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import CountUp from 'react-countup'
import { motion } from 'framer-motion'

// import img
import pic1 from '../assets/images/about/apic3.png'
import pic2 from '../assets/images/about/apic2.png'
import pic3 from '../assets/images/about/apic1.png'


const accordian = [
  { icon: "flaticon-crane", key: 0, heading: "Unrivalled Workmanship", text: "Every structural element, from the frame to the finishing touches, is executed with exacting attention and care." },
  { icon: "flaticon-interior-design", key: 1, heading: "Engineering Excellence", text: "Our team includes certified engineers who guarantee that each build meets rigorous technical standards, promoting longevity and safety." },
  { icon: "flaticon-blueprint", key: 2, heading: "Sustainable Solutions", text: "We employ environmentally responsible methods, energy-efficient materials, and future-ready designs." },
  { icon: "flaticon-home", key: 3, heading: "Seamless Project Management", text: "With clear milestones, transparent communication, and proactive coordination, we deliver projects on schedule and within budget." },
  { icon: "flaticon-graphic-designer", key: 4, heading: "Local Expertise", text: "Our familiarity with local codes, regulations, and conditions ensures smooth approvals and regionally tailored results." }
];

const AccordianItem = ({ icon, heading, text, itemKey }) => {
  return (
    <Accordion.Item eventKey={`${itemKey}`}>
      <motion.div
        initial={{ opacity: 0, x: "100%" }}
        whileInView={{ opacity: 1, x: "0%" }}
        transition={{ duration: 1 }}
      >
        <Accordion.Header className="accordion-header" id="flush-headingOne">
          <i className={icon}></i>
          {heading}
          <span className="toggle-close"></span>
        </Accordion.Header>
        <Accordion.Body>
          
          {(itemKey === 1 || itemKey === 3) ? (
            text.split('\n').filter(line => line.trim() !== '').map((line, index) => (
              <p key={index}>{line.trim()}</p>
            ))
          ) : (
            <p>{text}</p>
          )}
        </Accordion.Body>
      </motion.div>
    </Accordion.Item>
  );
}


const Working = () => {
  return (
    <>
      <div className="row" style={{marginLeft: '10%', marginBottom: '10%'}}>
        <div className="col-lg-6" style={{ marginTop: '10%', weight: ' 80%', height: '50%'}}>
          <div className="dz-media">
            <div className="img2 aos-item" style={{ marginRight: '5%', marginBottom: '30%', marginTop: '0%' }}>
              <img src={pic2} alt="" />
            </div>
            <div className="img3 aos-item" style={{marginBottom: '50%', marginLeft: '0%' }}>
              <img src={pic3} alt="" />
            <div className="img1 aos-item" style={{ marginLeft: '-60%', marginBottom: '0%' }}>
              <img src={pic1} alt="" />
            </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6" style={{weight: ' 100%', height: '100%'}}>
          <div className="year-exp">
            <motion.h4 className="text aos-item" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600"
              initial={{ opacity: 0, y: "100%" }}
              whileInView={{ y: "0%", opacity: 1 }}
              transition={{ duration: 1, delay: 0.8 }}
              style={{ textAlign: 'justify' }}
            >WHY CHOOSE US</motion.h4>
          </div>
          <motion.p className="m-b30 aos-item" data-aos="fade-up" data-aos-duration="800" data-aos-delay="400"
            initial={{ opacity: 0, y: "100%" }}
            whileInView={{ opacity: 1, y: "0%" }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            {/* Building dreams, building futures. Our team of professionals is dedicated to sustainable construction, ensuring each project reflects superior quality, lasting durability, and a commitment to eco-friendly practices. Whether it's a grand commercial centre or a welcoming residential community, we are committed to customer satisfaction and use our expertise and passion to build a greener tomorrow. */}
          </motion.p>
          <div className="accordion dz-accordion about-faq" id="aboutFaq">
            <Accordion defaultActiveKey="0" style={{ textAlign: 'justify' }}>
              {accordian.map((item, index) => (
                <AccordianItem
                  key={index}
                  icon={item.icon}
                  heading={item.heading}
                  text={item.text}
                  itemKey={item.key}
                />
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  )
}

export default Working
