import React, { useRef, useState, useEffect } from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import emailjs from 'emailjs-com';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const cards = [
  { id: '01', icon: "flaticon-telephone", title: "CALL NOW", detail: " ", detail2: "+61 (08) 82579123" },
  { id: '02', icon: "flaticon-email", title: "EMAIL", detail: " ", detail2: "info@cyberidge.com.au" },
  { id: '03', icon: "flaticon-placeholder", title: "LOCATION", detail: "3/22-28 Melbourne St, North Adelaide, SA 5006", },
]

const ContectUs = () => {
  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => setAlertMessage(null), 5000);
      return () => clearTimeout(timer);
    }
  }, [alertMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!loading) {
      setSuccess(false);
      setLoading(true);

      if (formRef.current) {
        emailjs.sendForm('service_liawngn', 'template_ijguzko', formRef.current, '6oRiaS7RZCoeRMZxV')
            .then((result) => {
              console.log('Email successfully sent!', result.text);
              setAlertMessage('Submit Successfully');
              setAlertSeverity('success');
              setSuccess(true);
              setLoading(false);

              setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
              });
            }, (error) => {
              console.log('Failed to send email.', error.text);
              setAlertMessage('Failed to send email.');
              setAlertSeverity('error');
              setLoading(false);
            });
      } else {
        console.log('Form reference not found');
        setLoading(false);
      }
    }
  };

  return (
      <>
        <div className="page-content bg-white">
          <CommanBanner mainTitle="CONTACT US" bgImage={IMAGES.bnr9} />
          <section className="content-inner">
            <div className="container">
              <div className="row">
                {cards.map((i) => (
                    <div key={i.id} className="col-lg-4 col-md-12 m-b30 aos-item" style={{ justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                      <div className="icon-bx-wraper style-8 bg-white" >
                        <div className="icon-md m-r20">
                          <span className="icon-cell text-primary"><i className={i.icon}></i></span>
                        </div>
                        <div className="icon-content">
                          <h4 className="tilte m-b10">{i.title}</h4>
                          <p className="m-b0">{i.detail}<br />{i.detail2}</p>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
          </section>
          <section className="content-inner-1 pt-0">
            <div className="map-iframe">
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3272.0415146450982!2d138.6073984750736!3d-34.90540767349253!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ab0c93d21bc11eb%3A0xacefe376c63ebb42!2sUnit%203%2F22%20Melbourne%20St%2C%20North%20Adelaide%20SA%205006!5e0!3m2!1sen!2sau!4v1700006371526!5m2!1sen!2sau"
                  className="align-self-stretch radius-sm" style={{border: 0, width: '100%', minHeight: '100%'}}
                  allowFullScreen></iframe>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div className="container">
              <div className="contact-area aos-item">
                <div className="section-head style-1 text-center">
                  <h6 className="sub-title text-primary">CONTACT US</h6>
                  <h2 className="title">Get In Touch With Us</h2>
                </div>
                <form ref={formRef} onSubmit={sendEmail} className="dz-form dzForm contact-bx" id="contact-form">
                  <div className="dzFormMsg"></div>
                  <div className="row sp10">
                    <div className="col-sm-6 m-b20">
                      <input type="text" className="form-control" required name="firstName" placeholder="First Name"
                             value={formData.firstName} onChange={handleChange}/>
                    </div>
                    <div className="col-sm-6 m-b20">
                      <input type="text" className="form-control" required name="lastName" placeholder="Last Name"
                             value={formData.lastName} onChange={handleChange}/>
                    </div>
                    <div className="col-sm-12 m-b20">
                      <input type="email" className="form-control" required name="email" placeholder="Email"
                             value={formData.email} onChange={handleChange}/>
                    </div>
                    <div className="col-sm-12 m-b20">
                      <input type="text" className="form-control" required name="phone" placeholder="Phone No."
                             value={formData.phone} onChange={handleChange}/>
                    </div>
                    <div className="col-sm-12 m-b20">
                      <input type="text" className="form-control" required name="subject" placeholder="Subject"
                             value={formData.subject} onChange={handleChange}/>
                    </div>
                    <div className="col-sm-12 m-b20">
                      <textarea name="message" rows="5" className="form-control" placeholder="Message"
                                value={formData.message} onChange={handleChange}></textarea>
                    </div>

                    <div className="col-sm-12 text-center">
                      {!success && (
                          <button type="submit" name="submit" className="btn btn-primary btn-rounded"
                                  disabled={loading}>
                            {loading ? (
                                <>
                                  <CircularProgress size={20} sx={{color: 'white', alignItems: 'center', justifyContent: 'center'}}/>
                                  Sending...
                                </>
                            ) : (
                                <>
                                  SUBMIT <i className="m-l10 fas fa-caret-right"></i>
                                </>
                            )}
                          </button>
                      )}
                    </div>
                  </div>
                </form>

                {alertMessage && (
                    <Stack
                        sx={{
                          position: 'fixed',
                          bottom: 20,
                          left: 20,
                          zIndex: 9999,
                          width: 'auto',
                          maxWidth: '300px',
                        }}
                    >
                      <Alert severity={alertSeverity} onClose={() => setAlertMessage(null)}>
                        {alertMessage}
                      </Alert>
                    </Stack>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
  )
}

export default ContectUs;
