import React, { useState } from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import ModalVideo from 'react-modal-video'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import pooraka1 from '../assets/images/latestProjectImage/pooraka.png'
import pooraka2 from '../assets/images/upcomingProjectImage/pooraka.png'
import pooraka3 from '../assets/images/upcomingProjectImage/pooraka1.png'
import pooraka4 from '../assets/images/latestProjectImage/pooraka1.jpg'





const galleryBlog = [
    { img: IMAGES.service1 },
    { img: IMAGES.service2 },
    { img: IMAGES.service3 },
];

const SwipperSlide = [

    { swipperImg: IMAGES.workPic1, changeStyle: "mt-5" },
    { swipperImg: IMAGES.workPic2, },
    { swipperImg: IMAGES.workPic3, changeStyle: "mt-5" },
    { swipperImg: IMAGES.workPic1 },
    { swipperImg: IMAGES.workPic2, changeStyle: "mt-5" },
    { swipperImg: IMAGES.workPic3 },
    { swipperImg: IMAGES.workPic1, changeStyle: "mt-5" },
    { swipperImg: IMAGES.workPic2 },
    { swipperImg: IMAGES.workPic3, changeStyle: "mt-5" },
]

const PortfolioDetail = () => {

    const [isOpen, setOpen] = useState(false);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const firstItem=galleryBlog[0];
    const secondItem=galleryBlog[1];
    const thirdItem=galleryBlog[2];

    return (
        <>
        
            <div className="page-content bg-white">

                <CommanBanner mainTitle={<><span>Pooraka</span><br/><span>SA 5095</span></>} bgImage={pooraka2} />
                <section className="section-full content-inner-2 port-detail" style={{ backgroundImage: `url(${IMAGES.bg2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: "no-repeat" }}>
                    <div className="container">
                        <div className="row mb-lg-5 mb-3 ">
                            <div className="col-lg-12 col-md-12 m-b50 aos-item m-sm-b30" data-aos="fade-up"
                                 data-aos-duration="1000" data-aos-delay="200">
                                <img src={pooraka3} alt=""/>
                            </div>
                            <div className="col-lg-6 col-md-12 align-self-center aos-item" data-aos="fade-up"
                                 data-aos-duration="1000" data-aos-delay="400">
                                <h1 className="dz-title">Pooraka SA 5095</h1>
                                <br></br>
                                <br></br>
                                <p>This thoughtfully planned residential project is located in the vibrant suburb of Pooraka.
                                    We take great pride in delivering high-quality homes within a reasonable
                                    budget, ensuring a comfortable and welcoming living environment for future
                                    residents. Each dwelling has been designed with meticulous attention to material
                                    selection and detail, fostering a contemporary yet inviting ambiance. We look
                                    forward to seeing this project come to life, providing homeowners with an ideal
                                    community setting in which to settle and thrive.</p>
                                {/*<p className="m-b30"></p>*/}
                            </div>
                            <div className="col-lg-6 col-md-12 m-b30 aos-item" data-aos="fade-up"
                                 data-aos-duration="1000" data-aos-delay="600">
                                <img src={pooraka1} className="d-lg-block d-none" alt=""/>
                            </div>
                            <img src={pooraka4} className="d-lg-block d-none" alt=""/>
                        </div>
                    </div>
                </section>


                {/*                <div className="content-inner-2" style={{ backgroundColor: "#dfdbdb" }}>*/}
{/*                    <div className="container">*/}
{/*                        <div className="row lightgallery">*/}
{/*                            /!* <div className="col-lg-12 col-md-12 m-b30 aos-item" data-aos="fade-up" data-aos-duration="1000"*/}
{/*                                data-aos-delay="200">*/}
{/*                                <div className="video-bx content-media style-2 shadow">*/}
{/*                                    <img src={IMAGES.video} alt="" />*/}
{/*                                    <div className="video-btn aos-item aos-init aos-animate" data-aos="zoom-in"*/}
{/*                                        data-aos-duration="1000" data-aos-delay="400">*/}
{/*                                        <Link onClick={() => { setOpen(true) }} className="popup-youtube"><i*/}
{/*                                            className="fas fa-play"></i></Link>*/}
{/*                                    </div>*/}
{/*                                </div>*/}
{/*                            </div> *!/*/}


{/*                                <Lightbox*/}
{/*                                    open={galleryOpen}*/}
{/*                                    close={() => setGalleryOpen(false)}*/}
{/*                                    slides={[*/}
{/*                                        { src: IMAGES.service1 },*/}
{/*                                        { src: IMAGES.service2 },*/}
{/*                                        { src: IMAGES.service3 },*/}
{/*                                    ]}*/}
{/*                                />*/}

{/*                            {*/}
{/*                             */}
{/*                                    <div key={firstItem} className="col-lg-4 col-md-4 m-b30 aos-item" data-aos="fade-up" data-aos-duration="1000"*/}
{/*                                        data-aos-delay="200">*/}
{/*                                        <div className="dz-box overlay style-1">*/}
{/*                                            <div className="dz-media">*/}
{/*                                                <img src={firstItem.img} alt="" />*/}
{/*                                            </div>*/}
{/*                                            /!* <div className="dz-info">*/}
{/*                                                <span onClick={() => { setGalleryOpen(true) }} data-exthumbimage={firstItem.img} data-src={firstItem.img}*/}
{/*                                                    className="view-btn lightimg" title="ARCHITECTURAL"></span>*/}
{/*                                            </div> *!/*/}
{/*                                        </div>*/}
{/*                                    </div>*/}
{/*                                */}
{/*                            }*/}

{/*                            */}
{/*                    {*/}
{/*                             */}
{/*                             <div key={secondItem} className="col-lg-4 col-md-4 m-b30 aos-item" data-aos="fade-up" data-aos-duration="1000"*/}
{/*                                 data-aos-delay="200">*/}
{/*                                 <div className="dz-box overlay style-1">*/}
{/*                                     <div className="dz-media">*/}
{/*                                         <img src={secondItem.img} alt="" />*/}
{/*                                     </div>*/}
{/*                                     /!* <div className="dz-info">*/}
{/*                                         <span onClick={() => { setGalleryOpen(true) }} data-exthumbimage={secondItem.img} data-src={secondItem.img}*/}
{/*                                             className="view-btn lightimg" title="ARCHITECTURAL"></span>*/}
{/*                                     </div> *!/*/}
{/*                                 </div>*/}
{/*                             </div>*/}
{/*                         */}
{/*                     }*/}

{/*{*/}
{/*                             */}
{/*                             <div key={thirdItem} className="col-lg-4 col-md-4 m-b30 aos-item" data-aos="fade-up" data-aos-duration="1000"*/}
{/*                                 data-aos-delay="200">*/}
{/*                                 <div className="dz-box overlay style-1">*/}
{/*                                     <div className="dz-media">*/}
{/*                                         <img src={thirdItem.img} alt="" />*/}
{/*                                     </div>*/}
{/*                                     /!* <div className="dz-info">*/}
{/*                                         <span onClick={() => { setGalleryOpen(true) }} data-exthumbimage={thirdItem.img} data-src={thirdItem.img}*/}
{/*                                             className="view-btn lightimg" title="ARCHITECTURAL"></span>*/}
{/*                                     </div> *!/*/}
{/*                                 </div>*/}
{/*                             </div>*/}
{/*                         */}
{/*                     }*/}

{/*                            */}
{/*                        </div>*/}
{/*                    </div>*/}
{/*                </div>*/}


                <section className="content-inner-1">
                    <div className="container m-b70">
                        {/* <div className="section-head style-1 text-center">
                            <h6 className="sub-title text-primary">OUR PORTFOLIOS</h6>
                            <h2 className="title">Our Latest Projects</h2>
                        </div> */}
                    </div>
                    <div className="container-fluid">
                        <Swiper className="swiper-container swiper-portfolio lightgallery aos-item" data-aos="fade-in"
                            data-aos-duration="1000" data-aos-delay="400"
                            slidesPerView={4}
                            spaceBetween={30}
                            breakpoints={{
                                1280: {
                                    slidesPerView: 4,
                                },
                                991: {
                                    slidesPerView: 3,
                                },
                                591: {
                                    slidesPerView: 2,
                                },
                                300: {
                                    slidesPerView: 1,
                                },
                            }}
                        >

                        </Swiper>
                    </div>
                            {/* <div className="swiper-wrapper">
                                {
                                    SwipperSlide.map((item, i) => (
                                        <SwiperSlide className="swiper-slide" key={i}>
                                            <div className={`dz-box overlay style-1 ${item.changeStyle}`}>
                                                <div className="dz-media">
                                                    <img src={item.swipperImg} alt="" />
                                                </div>
                                                <div className="dz-info">
                                                    <span data-exthumbimage="images/work/work-1/pic-1.jpg"
                                                        data-src="images/work/work-1/pic-1.jpg" className="view-btn lightimg"
                                                        title="INTERIOR DESIGN"
                                                    >                                                        
                                                    </span>
                                                    <h6 className="sub-title">INTERIOR DESIGN</h6>
                                                    <h4 className="title m-b15">
                                                        <Link to="/portfolio-details">Modern House Interior
                                                            <span>New York</span>
                                                        </Link>
                                                        </h4>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </div> */}

                      
                </section>

                <ModalVideo channel="youtube" autoplay
                    isOpen={isOpen} videoId="sNCv3_NTNtU" onClose={() => setOpen(false)}
                />
            </div>
        </>
    )
}

export default PortfolioDetail