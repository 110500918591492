import React, { useRef, useState, useEffect } from 'react';
import CommanBanner from "../elements/CommanBanner";
import { IMAGES } from "../constants/theme";
import emailjs from 'emailjs-com';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Warranty = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        inquiryType: '',
        projectRefNumber: '',
        description: '',
        contactMethod: '',
    });

    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const formRef = useRef(null);

    useEffect(() => {
        if (alertMessage) {
            const timer = setTimeout(() => setAlertMessage(null), 5000);
            return () => clearTimeout(timer);
        }
    }, [alertMessage]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const sendEmail = (e) => {
        e.preventDefault();

        if (!loading) {
            setSuccess(false);
            setLoading(true);

            if (formRef.current) {
                emailjs.sendForm('service_liawngn', 'template_jcap0qm', formRef.current, '6oRiaS7RZCoeRMZxV')
                    .then((result) => {
                        console.log('Email successfully sent!', result.text);
                        setAlertMessage('Submit Successfully');
                        setAlertSeverity('success');
                        setSuccess(true);
                        setLoading(false);

                        setFormData({
                            firstName: '',
                            lastName: '',
                            email: '',
                            phone: '',
                            inquiryType: '',
                            projectRefNumber: '',
                            description: '',
                            contactMethod: '',
                        });
                    }, (error) => {
                        console.log('Failed to send email.', error.text);
                        setAlertMessage('Failed to send email.');
                        setAlertSeverity('error');
                        setLoading(false);
                    });
            } else {
                console.log('Form reference not found');
                setLoading(false);
            }
        }
    };

    return (
        <>
            <div className="page-content bg-white">
                <CommanBanner mainTitle="Warranty" bgImage={IMAGES.bnr9}/>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <section className="content-inner-1 pt-0">
                    <div className="container">
                        <div className="contact-area aos-item">
                            <div className="section-head style-1 text-center">
                                <h6 className="sub-title text-primary">Warranty</h6>
                                <h2 className="title">Submit Your Warranty Request</h2>
                            </div>
                            <form ref={formRef} onSubmit={sendEmail} className="dz-form dzForm contact-bx" id="warranty-form">
                                <div className="dzFormMsg"></div>
                                <div className="row sp10">
                                    <div className="col-sm-6 m-b20">
                                        <input type="text" className="form-control" required name="firstName"
                                               placeholder="First Name"
                                               value={formData.firstName} onChange={handleChange}/>
                                    </div>
                                    <div className="col-sm-6 m-b20">
                                        <input type="text" className="form-control" required name="lastName"
                                               placeholder="Last Name"
                                               value={formData.lastName} onChange={handleChange}/>
                                    </div>
                                    <div className="col-sm-12 m-b20">
                                        <input type="email" className="form-control" required name="email"
                                               placeholder="Email"
                                               value={formData.email} onChange={handleChange}/>
                                    </div>
                                    <div className="col-sm-12 m-b20">
                                        <input type="text" className="form-control" required name="phone"
                                               placeholder="Phone No."
                                               value={formData.phone} onChange={handleChange}/>
                                    </div>

                                    <div className="col-sm-12 m-b20">
                                        <input type="text" className="form-control" required name="projectRefNumber"
                                               placeholder="Project Reference Number"
                                               value={formData.projectRefNumber} onChange={handleChange}/>
                                    </div>
                                    <div className="col-sm-12 m-b20">
                    <textarea name="description" rows="5" className="form-control" placeholder="Description"
                              value={formData.description} onChange={handleChange}></textarea>
                                    </div>

                                    <div className="col-sm-6 m-b20">
                                        <FormControl className="input-group">
                                            <Select
                                                name="contactMethod"
                                                value={formData.contactMethod}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{'aria-label': 'Without label'}}
                                            >
                                                <MenuItem value={'Mobile Phone'}>Mobile Phone</MenuItem>
                                                <MenuItem value={'Email'}>Email</MenuItem>
                                            </Select>
                                            <FormHelperText>Preferred Contact Method</FormHelperText>
                                        </FormControl>
                                    </div>

                                    <div className="col-sm-6 m-b20">
                                        <FormControl className="input-group">
                                            <Select
                                                name="inquiryType"
                                                value={formData.inquiryType}
                                                onChange={handleChange}
                                                displayEmpty
                                                inputProps={{'aria-label': 'Without label'}}
                                            >
                                                <MenuItem value={'Warranty Claim'}>Warranty Claim</MenuItem>
                                                <MenuItem value={'Maintenance Request'}>Maintenance Request</MenuItem>
                                                <MenuItem value={'General Inquiry'}>General Inquiry</MenuItem>
                                            </Select>
                                            <FormHelperText>Type of Inquiry</FormHelperText>
                                        </FormControl>
                                    </div>

                                    <div className="col-sm-12 text-center">
                                        {!success && (
                                            <button type="submit" name="submit" className="btn btn-primary btn-rounded"
                                                    disabled={loading}>
                                                {loading ? (
                                                    <>
                                                        <CircularProgress size={20} sx={{
                                                            color: 'white',
                                                            alignItems: 'center',
                                                            justifyContent: 'center'
                                                        }}/>
                                                        Sending...
                                                    </>
                                                ) : (
                                                    <>
                                                        SUBMIT <i className="m-l10 fas fa-caret-right"></i>
                                                    </>
                                                )}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>

                            {alertMessage && (
                                <Stack sx={{position: 'fixed', bottom: 20, left: 20, zIndex: 9999, maxWidth: '300px'}}>
                                    <Alert severity={alertSeverity} onClose={() => setAlertMessage(null)}>
                                        {alertMessage}
                                    </Alert>
                                </Stack>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Warranty;
